/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function ThermostatHeatCoolIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={19}
      height={18}
      fill='none'
      {...props}
    >
      <path
        fill='#6B95FF'
        d='M13.633 17.56v-2.021l-1.583 1.558-.681-.681 2.264-2.265v-.974h-.974l-2.264 2.265-.682-.682 1.558-1.583h-2.02v-.974h2.02l-1.558-1.582.682-.682 2.264 2.264h.974v-.974L11.37 8.965l.681-.682 1.583 1.558v-2.02h.974v2.02l1.583-1.558.681.682-2.264 2.264v.974h.974l2.264-2.264.682.682-1.558 1.582h2.02v.974h-2.02l1.558 1.583-.682.682-2.264-2.265h-.974v.974l2.264 2.265-.681.681-1.583-1.558v2.02h-.974Z'
      />
      <path
        fill='#FC8E28'
        d='M.91 6.201c0-.926.217-1.751.652-2.475.434-.724.912-1.332 1.433-1.827.522-.494 1-.871 1.434-1.131l.651-.39v1.746c0 .327.109.585.326.775.217.19.46.284.73.284.148 0 .289-.03.423-.092.135-.062.259-.164.372-.305l.234-.29a4.22 4.22 0 0 1 1.512 1.541c.382.658.573 1.379.573 2.164 0 .777-.186 1.485-.56 2.125A4.25 4.25 0 0 1 7.217 9.84c.148-.211.263-.443.346-.695.082-.251.124-.518.124-.8a2.576 2.576 0 0 0-.756-1.84l-1.85-1.84-1.838 1.84a2.699 2.699 0 0 0-.573.847c-.13.309-.196.64-.196.993 0 .282.041.549.124.8.082.252.197.484.345.695a4.25 4.25 0 0 1-1.472-1.515A4.136 4.136 0 0 1 .91 6.2Zm4.17-.053 1.108 1.099c.148.15.26.318.339.503.078.185.117.384.117.596 0 .432-.152.8-.456 1.105a1.507 1.507 0 0 1-1.108.457c-.434 0-.803-.153-1.107-.457a1.506 1.506 0 0 1-.456-1.105 1.578 1.578 0 0 1 .456-1.099L5.08 6.148Z'
      />
    </svg>
  )
}
