/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function SearchIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      fill='none'
      {...props}
    >
      <mask
        id='search_svg__a'
        width={20}
        height={20}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 0h20v20H0z' />
      </mask>
      <g mask='url(#search_svg__a)'>
        <path
          fill='#6E7179'
          d='m16.333 17.5-5.25-5.25a5.08 5.08 0 0 1-3.167 1.083c-1.513 0-2.794-.524-3.843-1.572C3.024 10.71 2.5 9.43 2.5 7.917c0-1.514.524-2.796 1.573-3.845C5.122 3.024 6.403 2.5 7.917 2.5c1.514 0 2.795.524 3.844 1.572 1.048 1.05 1.572 2.33 1.572 3.845a5.08 5.08 0 0 1-1.083 3.166l5.25 5.25-1.167 1.167Zm-8.416-5.833c1.041 0 1.927-.365 2.656-1.094.73-.73 1.094-1.615 1.094-2.656 0-1.042-.365-1.928-1.094-2.657-.73-.729-1.615-1.093-2.656-1.093-1.042 0-1.928.364-2.657 1.093-.729.73-1.093 1.615-1.093 2.657 0 1.041.364 1.927 1.093 2.656.73.73 1.615 1.094 2.657 1.094Z'
        />
      </g>
    </svg>
  )
}
