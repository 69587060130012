/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function InfoIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={19}
      fill='none'
      {...props}
    >
      <mask
        id='info_svg__a'
        width={18}
        height={19}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 .5h18v18H0z' />
      </mask>
      <g mask='url(#info_svg__a)'>
        <path
          fill='#D5D8DC'
          d='M8.25 13.25h1.5v-4.5h-1.5v4.5Zm.75-6c.213 0 .39-.072.535-.216A.725.725 0 0 0 9.75 6.5a.728.728 0 0 0-.215-.535A.728.728 0 0 0 9 5.75a.725.725 0 0 0-.534.215.726.726 0 0 0-.216.535c0 .213.072.39.216.534A.724.724 0 0 0 9 7.25ZM9 17a7.3 7.3 0 0 1-2.925-.591 7.569 7.569 0 0 1-2.381-1.603 7.569 7.569 0 0 1-1.603-2.381A7.3 7.3 0 0 1 1.5 9.5a7.3 7.3 0 0 1 .591-2.925 7.569 7.569 0 0 1 1.603-2.381A7.58 7.58 0 0 1 6.075 2.59 7.307 7.307 0 0 1 9 2c1.037 0 2.012.197 2.925.59a7.58 7.58 0 0 1 2.381 1.604 7.569 7.569 0 0 1 1.603 2.381A7.3 7.3 0 0 1 16.5 9.5a7.3 7.3 0 0 1-.591 2.925 7.569 7.569 0 0 1-1.603 2.381 7.569 7.569 0 0 1-2.381 1.603A7.3 7.3 0 0 1 9 17Zm0-1.5c1.675 0 3.094-.581 4.256-1.744C14.42 12.594 15 11.175 15 9.5c0-1.675-.581-3.094-1.744-4.256C12.094 4.08 10.675 3.5 9 3.5c-1.675 0-3.094.581-4.256 1.744C3.58 6.406 3 7.825 3 9.5c0 1.675.581 3.094 1.744 4.256C5.906 14.92 7.325 15.5 9 15.5Z'
        />
      </g>
    </svg>
  )
}
