/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function RadioCheckedIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <path
        fill='#2196F3'
        d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5Zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Z'
      />
    </svg>
  )
}
