/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function ExclamationCircleOutlineIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={25}
      fill='none'
      {...props}
    >
      <mask
        id='exclamation-circle-outline_svg__a'
        width={24}
        height={25}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 .018h24v24H0z' />
      </mask>
      <g mask='url(#exclamation-circle-outline_svg__a)'>
        <path
          fill='#E36857'
          d='M12 17.018a.968.968 0 0 0 .713-.288.967.967 0 0 0 .287-.712.97.97 0 0 0-.287-.713.97.97 0 0 0-.713-.287.967.967 0 0 0-.712.287.968.968 0 0 0-.288.713c0 .283.096.52.288.712a.965.965 0 0 0 .712.288Zm-1-4h2v-6h-2v6Zm1 9a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.959-2.137-3.175a9.733 9.733 0 0 1-.788-3.9c0-1.384.263-2.684.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138a9.743 9.743 0 0 1 3.9-.787c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175a9.733 9.733 0 0 1 .788 3.9 9.733 9.733 0 0 1-.788 3.9 10.093 10.093 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137a9.733 9.733 0 0 1-3.9.788Zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.143 20 14.25 20 12.018c0-2.234-.775-4.125-2.325-5.675-1.55-1.55-3.442-2.325-5.675-2.325-2.233 0-4.125.775-5.675 2.325C4.775 7.893 4 9.784 4 12.018c0 2.233.775 4.125 2.325 5.675 1.55 1.55 3.442 2.325 5.675 2.325Z'
        />
      </g>
    </svg>
  )
}
