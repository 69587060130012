/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function ChevronDownIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      fill='none'
      {...props}
    >
      <mask
        id='chevron-down_svg__a'
        width={20}
        height={20}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 0h20v20H0z' />
      </mask>
      <g mask='url(#chevron-down_svg__a)'>
        <path
          fill='#6E7179'
          d='m10 12.813-5-5 1.167-1.167L10 10.479l3.833-3.833L15 7.813l-5 5Z'
        />
      </g>
    </svg>
  )
}
