/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function InfoDarkIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={19}
      height={19}
      fill='none'
      {...props}
    >
      <circle cx={9.755} cy={9.416} r={6.655} fill='#E9EDEF' />
      <mask
        id='info-dark_svg__a'
        width={19}
        height={19}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M.755.416h18v18h-18z' />
      </mask>
      <g mask='url(#info-dark_svg__a)'>
        <path
          fill='#9DA1A9'
          d='M9.005 13.166h1.5v-4.5h-1.5v4.5Zm.75-6c.213 0 .391-.072.535-.216a.725.725 0 0 0 .215-.534.728.728 0 0 0-.215-.534.728.728 0 0 0-.535-.216.725.725 0 0 0-.534.216.726.726 0 0 0-.216.534c0 .213.072.39.216.534a.724.724 0 0 0 .534.216Zm0 9.75a7.3 7.3 0 0 1-2.925-.59 7.569 7.569 0 0 1-2.38-1.603 7.568 7.568 0 0 1-1.604-2.382 7.3 7.3 0 0 1-.59-2.925 7.3 7.3 0 0 1 .59-2.925 7.569 7.569 0 0 1 1.603-2.38A7.58 7.58 0 0 1 6.83 2.506a7.307 7.307 0 0 1 2.925-.59c1.038 0 2.013.196 2.925.59a7.579 7.579 0 0 1 2.382 1.603 7.569 7.569 0 0 1 1.602 2.381 7.3 7.3 0 0 1 .591 2.925 7.3 7.3 0 0 1-.59 2.925 7.569 7.569 0 0 1-1.603 2.382 7.569 7.569 0 0 1-2.382 1.602 7.3 7.3 0 0 1-2.925.591Zm0-1.5c1.675 0 3.094-.58 4.257-1.743 1.162-1.163 1.743-2.582 1.743-4.257 0-1.675-.58-3.093-1.743-4.256-1.163-1.162-2.582-1.744-4.257-1.744-1.675 0-3.093.582-4.256 1.744-1.162 1.163-1.744 2.581-1.744 4.256 0 1.675.582 3.094 1.744 4.257 1.163 1.162 2.581 1.743 4.256 1.743Z'
        />
      </g>
    </svg>
  )
}
