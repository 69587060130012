/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function OffIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16}
      height={16}
      fill='none'
      {...props}
    >
      <mask
        id='off_svg__a'
        width={16}
        height={16}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M.421.5h15v15h-15z' />
      </mask>
      <g mask='url(#off_svg__a)'>
        <path
          fill='#9DA1A9'
          d='M12.78 14.64 11.36 13.22a6.404 6.404 0 0 1-1.633.765 6.127 6.127 0 0 1-1.805.266 6.086 6.086 0 0 1-2.437-.492 6.311 6.311 0 0 1-1.985-1.336 6.312 6.312 0 0 1-1.336-1.985A6.086 6.086 0 0 1 1.671 8c0-.625.089-1.227.266-1.805a6.4 6.4 0 0 1 .766-1.633L1.28 3.141l.89-.891 11.5 11.5-.89.89ZM7.922 13a4.955 4.955 0 0 0 2.532-.688L3.609 5.47A4.956 4.956 0 0 0 2.92 8c0 1.385.487 2.565 1.461 3.54.974.973 2.154 1.46 3.54 1.46Zm5.219-1.563-.906-.906A4.955 4.955 0 0 0 12.922 8c0-1.385-.488-2.565-1.462-3.54C10.486 3.488 9.307 3 7.921 3a4.955 4.955 0 0 0-2.531.688l-.906-.907a6.4 6.4 0 0 1 1.633-.765A6.127 6.127 0 0 1 7.92 1.75c.865 0 1.677.164 2.438.492.76.328 1.422.774 1.984 1.336a6.311 6.311 0 0 1 1.336 1.985c.328.76.492 1.572.492 2.437 0 .625-.088 1.227-.265 1.805a6.399 6.399 0 0 1-.766 1.633Z'
        />
      </g>
    </svg>
  )
}
