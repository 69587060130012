/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function ThermostatHeatIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={12}
      height={14}
      fill='none'
      {...props}
    >
      <path
        fill='#FC8E28'
        d='M0 8.5c0-1.313.313-2.481.938-3.506S2.25 3.106 3 2.406c.75-.7 1.438-1.234 2.063-1.603L6 .25v2.475c0 .462.156.828.469 1.097a1.57 1.57 0 0 0 1.05.403c.212 0 .415-.044.61-.131.193-.088.371-.232.534-.432L9 3.25a6.024 6.024 0 0 1 2.175 2.184c.55.932.825 1.954.825 3.066 0 1.1-.269 2.103-.806 3.01a6.065 6.065 0 0 1-2.119 2.146c.213-.3.378-.628.497-.984s.178-.735.178-1.135a3.6 3.6 0 0 0-.281-1.415 3.718 3.718 0 0 0-.806-1.19L6 6.324 3.356 8.931a3.82 3.82 0 0 0-.825 1.2 3.532 3.532 0 0 0-.281 1.406c0 .4.06.779.178 1.135.119.356.284.684.497.984A6.064 6.064 0 0 1 .806 11.51C.27 10.603 0 9.6 0 8.5Zm6-.075 1.594 1.556c.212.213.375.45.487.713.113.262.169.543.169.843 0 .613-.219 1.135-.656 1.566A2.186 2.186 0 0 1 6 13.75a2.186 2.186 0 0 1-1.594-.647 2.117 2.117 0 0 1-.656-1.566c0-.287.056-.565.169-.834.112-.269.275-.51.487-.722L6 8.425Z'
      />
    </svg>
  )
}
