/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function AccessCodeKeyIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <path
        fill='#232B3A'
        fillRule='evenodd'
        d='m20.493 14.743 1.558-1.451.058-.06c.599-.701.598-1.855 0-2.556a.73.73 0 0 0-.07-.07l-.547-.49a1.863 1.863 0 0 0-1.377-.612L16.444 9.5l-.45-.575c-.18-.23-.411-.416-.819-.416H12.93a5.992 5.992 0 0 0-4.877-2.52c-3.311-.004-5.996 2.684-5.995 6.005.001 3.32 2.686 6.013 5.995 6.017a5.978 5.978 0 0 0 5.056-2.774l.011-.017.015-.026h6.008l.065.002c.26.006.85.02 1.285-.453Zm-.149-3.4.513.458a.484.484 0 0 1-.003.31l-1.505 1.401H12.717a.937.937 0 0 0-.804.456l-.223.374a4.303 4.303 0 0 1-3.635 1.987 4.333 4.333 0 0 1-4.32-4.335A4.315 4.315 0 0 1 8.05 7.67a4.32 4.32 0 0 1 3.617 1.966l.105.21c.217.407.944.407.944.407s1.421-.032 2.186 0l.443.567c.177.227.449.36.736.36l4.03.005c.056 0 .108.024.144.067a.948.948 0 0 0 .088.09Zm-13.92 1.86a1.2 1.2 0 0 0 1.2-1.202 1.2 1.2 0 0 0-1.2-1.203A1.2 1.2 0 0 0 5.225 12a1.2 1.2 0 0 0 1.2 1.202Z'
        clipRule='evenodd'
      />
    </svg>
  )
}
