/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function OnlineStatusDeviceOfflineIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={25}
      fill='none'
      {...props}
    >
      <path
        fill='#E36857'
        d='m1.411 4.931 1.373-1.373 18.432 18.431-1.373 1.373z'
      />
      <path
        fill='#E36857'
        fillRule='evenodd'
        d='M12 23.362c-1.95 0-3.604-.679-4.963-2.037C5.68 19.967 5 18.312 5 16.362v-8c0-.799.114-1.548.342-2.248L7.028 7.8c-.019.184-.028.371-.028.562v8c0 1.384.487 2.563 1.463 3.538.975.975 2.154 1.462 3.537 1.462s2.563-.487 3.537-1.462c.664-.664 1.102-1.423 1.314-2.276l1.58 1.58a6.913 6.913 0 0 1-1.469 2.12c-1.358 1.36-3.012 2.038-4.962 2.038Zm6.975-6.36c.017-.21.025-.423.025-.64v-8c0-1.95-.68-3.604-2.038-4.962C15.604 2.04 13.95 1.362 12 1.362c-1.95 0-3.604.68-4.963 2.038a7.413 7.413 0 0 0-.767.897l1.452 1.451c.204-.325.45-.633.74-.923.976-.975 2.155-1.463 3.538-1.463s2.563.488 3.537 1.463C16.512 5.8 17 6.979 17 8.362v6.664l1.975 1.975ZM15 12.361c.283 0 .52-.095.713-.287a.968.968 0 0 0 .287-.713.968.968 0 0 0-.287-.712.968.968 0 0 0-.713-.288.968.968 0 0 0-.713.288.968.968 0 0 0-.287.712c0 .284.096.521.287.713.192.192.43.287.713.287Zm-6 3c.283 0 .52-.095.713-.287a.968.968 0 0 0 .287-.713.968.968 0 0 0-.287-.712.967.967 0 0 0-.713-.288.967.967 0 0 0-.713.288.968.968 0 0 0-.287.712c0 .284.096.521.287.713.192.192.43.287.713.287Zm0 3c.283 0 .52-.095.713-.287a.968.968 0 0 0 .287-.713.968.968 0 0 0-.287-.712.967.967 0 0 0-.713-.288.967.967 0 0 0-.713.288.968.968 0 0 0-.287.712c0 .284.096.521.287.713.192.192.43.287.713.287Zm3 0c.283 0 .52-.095.713-.287a.968.968 0 0 0 .287-.713.968.968 0 0 0-.287-.712.968.968 0 0 0-.713-.288.968.968 0 0 0-.713.288.968.968 0 0 0-.287.712c0 .284.096.521.287.713.192.192.43.287.713.287Zm3.713-9.287a.968.968 0 0 1-.713.287.968.968 0 0 1-.713-.287.967.967 0 0 1-.287-.713c0-.283.096-.52.287-.712A.968.968 0 0 1 15 7.362c.283 0 .52.096.713.288.191.191.287.429.287.712 0 .284-.096.521-.287.713Zm-3.749.287c.283 0 .52-.096.713-.287a.967.967 0 0 0 .287-.713.967.967 0 0 0-.287-.712.968.968 0 0 0-.713-.288.968.968 0 0 0-.712.288.967.967 0 0 0-.288.712c0 .284.096.521.288.713.191.191.429.287.712.287Z'
        clipRule='evenodd'
      />
    </svg>
  )
}
