/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function OnlineStatusAccountOfflineIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={25}
      fill='none'
      {...props}
    >
      <mask
        id='online-status-account-offline_svg__a'
        width={24}
        height={25}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 .733h24v24H0z' />
      </mask>
      <g mask='url(#online-status-account-offline_svg__a)'>
        <path
          fill='#E36857'
          d='m19.8 23.333-2.65-2.6H6.5c-1.533 0-2.833-.534-3.9-1.6-1.067-1.067-1.6-2.367-1.6-3.9 0-1.284.396-2.425 1.188-3.425A5.286 5.286 0 0 1 5.25 9.883c.05-.134.1-.263.15-.388.05-.125.1-.262.15-.412L1.4 4.933l1.4-1.4 18.4 18.4-1.4 1.4Zm-13.3-4.6h8.65l-8.05-8.05a6.626 6.626 0 0 0-.075.525 5.28 5.28 0 0 0-.025.525h-.5c-.967 0-1.792.341-2.475 1.025A3.372 3.372 0 0 0 3 15.233c0 .966.342 1.791 1.025 2.475A3.372 3.372 0 0 0 6.5 18.733Zm15.1.75-1.45-1.4c.283-.234.496-.505.638-.813.141-.308.212-.654.212-1.037 0-.7-.242-1.292-.725-1.775-.483-.484-1.075-.725-1.775-.725H17v-2c0-1.384-.488-2.563-1.463-3.538-.975-.975-2.154-1.462-3.537-1.462-.45 0-.883.054-1.3.162-.417.109-.817.28-1.2.513l-1.45-1.45a7.028 7.028 0 0 1 1.862-.913A6.878 6.878 0 0 1 12 4.733c1.95 0 3.604.679 4.962 2.037C18.322 8.13 19 9.783 19 11.733c1.15.133 2.104.629 2.863 1.487A4.407 4.407 0 0 1 23 16.233a4.384 4.384 0 0 1-1.4 3.25Z'
        />
      </g>
    </svg>
  )
}
