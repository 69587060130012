/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function FanIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={18}
      fill='none'
      {...props}
    >
      <path
        fill='#9DA1A9'
        d='M9.921 16.456c-.61.164-1.124.106-1.539-.175a1.916 1.916 0 0 1-.81-1.12 2.188 2.188 0 0 1-.037-.963c.059-.33.207-.63.443-.899.218-.238.373-.51.464-.818.091-.306.127-.618.106-.933l-.244-.05a1.154 1.154 0 0 1-.231-.073l-1.495 1.036c-.184.126-.365.24-.544.338-.18.1-.37.177-.574.231-.755.202-1.51.052-2.268-.451-.757-.503-1.306-1.39-1.646-2.66-.164-.61-.106-1.123.174-1.539.28-.415.648-.684 1.104-.806.311-.083.635-.096.971-.038.337.057.64.204.908.44.238.219.51.373.817.465.307.09.619.126.934.106l.05-.245c.017-.081.041-.158.073-.231L5.54 6.577a6.808 6.808 0 0 1-.339-.545 2.446 2.446 0 0 1-.225-.556c-.206-.766-.057-1.528.446-2.286.503-.757 1.39-1.306 2.66-1.646.61-.164 1.123-.105 1.539.175.415.28.683.648.805 1.103.084.311.097.635.04.972a1.818 1.818 0 0 1-.441.907c-.219.238-.374.511-.465.818a2.655 2.655 0 0 0-.106.933l.245.05c.081.017.158.041.231.073l1.49-1.054c.184-.126.363-.235.538-.327.174-.091.363-.165.566-.22.97-.26 1.803-.052 2.497.622s1.173 1.502 1.436 2.485c.164.61.096 1.126-.202 1.546a2.021 2.021 0 0 1-2.075.845 1.865 1.865 0 0 1-.88-.448 2.064 2.064 0 0 0-.818-.464 2.655 2.655 0 0 0-.933-.106l-.05.244a1.154 1.154 0 0 1-.073.231l1.036 1.494c.123.173.232.34.326.5.095.161.167.337.219.529.22.775.082 1.55-.417 2.326-.498.775-1.388 1.335-2.67 1.678Zm-.63-6.378c.3-.08.526-.253.679-.519.153-.266.19-.548.11-.848-.08-.3-.253-.526-.52-.68a1.076 1.076 0 0 0-.847-.109c-.3.08-.526.253-.68.52-.153.265-.19.547-.11.847.081.3.254.526.52.68.266.153.548.19.848.11Z'
      />
    </svg>
  )
}
