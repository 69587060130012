/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function ThermostatOffIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={14}
      height={14}
      fill='none'
      {...props}
    >
      <path
        fill='#9DA1A9'
        d='M11.78 13.64 10.36 12.22a6.403 6.403 0 0 1-1.633.765 6.127 6.127 0 0 1-1.805.266 6.086 6.086 0 0 1-2.437-.492 6.311 6.311 0 0 1-1.985-1.336 6.312 6.312 0 0 1-1.336-1.985A6.086 6.086 0 0 1 .671 7c0-.625.089-1.227.266-1.805a6.4 6.4 0 0 1 .766-1.633L.28 2.141l.89-.891 11.5 11.5-.89.89ZM6.922 12a4.955 4.955 0 0 0 2.532-.688L2.609 4.47A4.956 4.956 0 0 0 1.92 7c0 1.385.487 2.565 1.461 3.54.974.973 2.154 1.46 3.54 1.46Zm5.219-1.563-.906-.906A4.955 4.955 0 0 0 11.922 7c0-1.385-.488-2.565-1.462-3.54C9.486 2.488 8.307 2 6.921 2a4.955 4.955 0 0 0-2.531.688l-.906-.907a6.401 6.401 0 0 1 1.633-.765A6.127 6.127 0 0 1 6.92.75c.865 0 1.677.164 2.438.492.76.328 1.422.774 1.984 1.336a6.311 6.311 0 0 1 1.336 1.985c.328.76.492 1.572.492 2.437 0 .625-.088 1.227-.265 1.805a6.4 6.4 0 0 1-.766 1.633Z'
      />
    </svg>
  )
}
