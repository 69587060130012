/*
 * Automaically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function CopyIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={25}
      height={25}
      fill='none'
      {...props}
    >
      <path
        fill='#232B3A'
        d='M5.84 22.988c-.55 0-1.02-.195-1.412-.587a1.928 1.928 0 0 1-.587-1.413v-14h2v14h11v2h-11Zm4-4c-.55 0-1.02-.195-1.411-.587a1.927 1.927 0 0 1-.588-1.413v-12c0-.55.196-1.02.588-1.413a1.925 1.925 0 0 1 1.412-.587h9c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413v12c0 .55-.196 1.021-.587 1.413a1.928 1.928 0 0 1-1.413.587h-9Zm0-2h9v-12h-9v12Z'
      />
    </svg>
  )
}
